import { useStaticQuery, graphql } from 'gatsby';
import { ImageDownloadOption } from './download';

interface SupportImage {
  locales: [
    {
      locale: string,
      title: string,
      purposeDescription: string
    }
  ],
  downloadOptions: ImageDownloadOption[]
}

export type DomaineImage = {
  info: {
      locale: string,
      title: string,
      purposeDescription: string
  },
  downloads: ImageDownloadOption[]
};

export default function getDomaineImages (locale: string): DomaineImage[] {
  const { images } = useStaticQuery(
    graphql`{
        images: markdownRemark(fields: {slug: {eq: "/support-domaine-images/"}}) {
          frontmatter {
             supportImages {
              locales {
                locale
                title
                purposeDescription
              }
              downloadOptions {
                downloadOption {
                  prettySize
                  publicURL
                  base
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 800)
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }`
  );

  const supportImages: SupportImage[] = images.frontmatter.supportImages;
  return supportImages.map(i => {
    return {
      info: i.locales.filter(l => l.locale === locale)[0],
      downloads: i.downloadOptions
    };
  });
}
