import React, { useContext, useEffect, useState } from 'react';

import { LocaleContext } from '../components/locale-context';
import getCircularLabels from '../queries/get-circular';

function goToAnchor(elementId: string) {
  document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
}

interface CircularMenuProps {
  children: React.ReactElement[] 
}
export function CircularMenu ({ children }: CircularMenuProps) {
  const { locale } = useContext(LocaleContext);
  const labels = getCircularLabels(locale);

  const [ options, setOptions ] = useState<string[]>([]);

  useEffect(() => {
    const newOptions: string[] = childRefs.map(c => {
      if (c && c.current) {
        const title = c.current.attributes.getNamedItem('title');
        return title ? title.value : '';
      }
      return '';
    });
    setOptions(newOptions);
  }, []);

  const refs = children.map(c => React.createRef<HTMLDivElement>());
  const childRefs = useState(refs)[0];

  const accountedChildren = React.Children.map(children, (c, i) => {
    return <div id = { i.toString() }>
      { React.cloneElement(c, { forwardRef: refs[i] }) }
    </div>;
  });

  return <div>
    <div className = "w3-container w3-padding w3-bar cpcolor-text-brown">
      { options.map((e, i) =>
        <div className = "w3-bar-item w3-padding cpcolor-border-brown" style = {{
        }}
        key = { i.toString() }
        >
          <div className = "w3-large w3-circle" style = {{
            width: '130px',
            height: '130px',
            borderStyle: 'solid',
            borderWidth: 'medium',
            position: 'relative'
          }}>
            <div className = "w3-circle cpcolor-hover-brown" style = {{
              position: 'absolute',
              cursor: 'pointer',
              top: '5px',
              bottom: '5px',
              right: '5px',
              left: '5px',
              borderStyle: 'solid',
              borderWidth: 'thin',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick = { () => goToAnchor(i.toString()) }
            title = { labels.circularMenuJump.replace('{0}', `"${e}"`) }
            >
              <p>{ e }</p>
            </div>
          </div>
        </div>
      )}
    </div>
    { accountedChildren }
  </div>;
}
