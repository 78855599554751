import React, { useRef, useState, useContext, ReactElement } from "react";

import { MenuData, MenuItem } from "../libs/menu-data";

import { ThemeContext, ThemeFactory, CarouselWidget } from "@chweb/commonui";
import PageSectionMenu, { PageSectionMenuOrientation } from "../components/page-section-menu";

type TabProps = {
  title: string;
  titleTooltip?: string;
  children: React.ReactNode;
};

export function Tab ({ children }: TabProps): ReactElement {
  return (<div style = {{ height: '100%' }}>
    { children }
  </div>);
}

export const TabBookOrientation = {
  HORIZONTAL: 0,
  VERTICAL: 1
} as const;
export type TabBookOrientation = typeof TabBookOrientation[keyof typeof TabBookOrientation];

type TabBookProps = {
  children: React.ReactNode;
  orientation?: TabBookOrientation;
  enableBorder?: boolean;
  basicStyle?: string;
  activeStyle?: string;
};

export function TabBook ({ children, orientation, enableBorder, basicStyle, activeStyle }: TabBookProps): ReactElement {
  const theme = useContext(ThemeContext) || ThemeFactory.make();

  const tabsRef = useRef<CarouselWidget>(null);
  const [active, setActive] = useState(0);

  const tabOptions: MenuItem[] = [];
  React.Children.forEach(children, tab => {
    if (!React.isValidElement(tab)) return;
    const { title, titleTooltip } = tab.props as TabProps;
    tabOptions.push(new MenuItem(title, titleTooltip));
  });

  const borderStyle = enableBorder ? `w3-border w3-round ${theme.border()}` : '';

  return orientation === TabBookOrientation.VERTICAL
    ? <div className = { `w3-row` }>
      <div className = { `w3-third w3-border-right ${theme.border()}` }>
        <PageSectionMenu
          menuData = { new MenuData(tabOptions) }
          active = { active }
          orientation = { PageSectionMenuOrientation.VERTICAL}
          onClick = { (index) => {
            setActive(index);
            if (tabsRef.current) {
              tabsRef.current.moveToIndex(index);
            }
          }}
          basicStyle = { basicStyle }
          activeStyle = { activeStyle }
        />
      </div>
      <div className = { `w3-container w3-twothird w3-padding-small` }>
        <CarouselWidget
          ref = { tabsRef }>
          { children }
        </CarouselWidget>
      </div>
    </div>
    : <div>
      <PageSectionMenu
        menuData = { new MenuData(tabOptions) }
        active = { active }
        orientation = { PageSectionMenuOrientation.HORIZONTAL }
        onClick = { (index) => {
          setActive(index);
          if (tabsRef.current) {
            tabsRef.current.moveToIndex(index);
          }
        }}
      />
      <div className = { `w3-padding-small ${borderStyle} ` }>
        <CarouselWidget
          ref = { tabsRef }>
          { children }
        </CarouselWidget>
      </div>
    </div>;
}
