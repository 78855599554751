import React, { useContext, ReactElement } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import ImageTitled from '../components/image-titled';
import { TabBook, Tab, TabBookOrientation } from '../components/tabbook';

import getB2BSupportImageLabels from '../queries/get-b2bsupport-img-labels';

import { ThemeContext, ThemeFactory } from "@chweb/commonui";
import { DomaineImage } from '../queries/get-domaine-images';

type SupportImagesProps = {
  options: DomaineImage[];
  locale: string;
};

export function SupportImages ({ options, locale }: SupportImagesProps): ReactElement {
  const labels = getB2BSupportImageLabels(locale);
  const labelPurpose = labels.b2bSupportImagesPurpose;
  const labelOptions = labels.b2bSupportImagesOptions;
  const labelPreview = labels.b2bSupportImagePreviewMessage;

  return <div className = 'w3-padding-16'>
    <TabBook orientation = { TabBookOrientation.VERTICAL } basicStyle = 'w3-medium' activeStyle = 'w3-medium' >
      {
        options.map((d, i) => {
          return <Tab title={d.info.title} key={i.toString()} >
            <SupportImage data={d} labelPurpose={labelPurpose} labelOptions={labelOptions} previewMessage={labelPreview} />
          </Tab>;
        })
      }
    </TabBook>
  </div>;
}

type SupportImageProps = {
  data: DomaineImage;
  labelPurpose: string;
  labelOptions: string;
  previewMessage: string;
};

function SupportImage ({ data, labelPurpose, labelOptions, previewMessage }: SupportImageProps): ReactElement {
  const theme = useContext(ThemeContext) || ThemeFactory.make();
  const previewDescription = previewMessage ? `** ${previewMessage} **\n ${data.info.purposeDescription}` : data.info.purposeDescription;
  return (
    <div className = 'w3-container'>
      <div className = "w3-row">
        <div className = "w3-half">
          <div className = "w3-container" style = {{ cursor: 'pointer', height: '300px' }} >
            <ImageTitled
              image = { data.downloads[0].downloadOption.childImageSharp.gatsbyImageData }
              description = { previewDescription }
              style = {{ height: '280px' }}
              imgStyle = {{ objectFit: 'contain' }} />
          </div>
        </div>
        <div className = "w3-half">
          <h4 className = { theme.header() }>{ labelPurpose }</h4>
          <p>
            { data.info.purposeDescription }
          </p>
          <h4 className = { theme.header() }>{ labelOptions }</h4>
          <table className = "w3-table" style = {{ width: '200px' }}>
            <tbody>
              {
                data.downloads.map((d, i) => {
                  const o = d.downloadOption;
                  const size = o.childImageSharp.original;
                  const description = `${size.width}x${size.height} (${o.prettySize})`;
                  return <tr key = { i.toString() }><td>
                    <a href = { o.publicURL } download = { o.base } >
                      <FontAwesomeIcon className = "w3-xlarge" icon = { faFileDownload } /> { description }</a>
                  </td></tr>;
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
