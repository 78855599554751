import { useStaticQuery, graphql } from 'gatsby';

import { reduceLocales } from '../libs/locale';

import {
  LocalizedLabels,
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export type ColorDef = {
  backgroundColor: string;
  textColor: string;
  locales: {
    purpose: string
  }
};

export default function getB2BSupportColors (locale: string): { labels: LocalizedLabels, colors: ColorDef[]} {
  const { labels, colors } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "b2bSupportColorsTitle"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
      colors: markdownRemark(fields: {slug: {eq: "/support-colors/"}}) {
        frontmatter {
           colorCodes {
             backgroundColor
             textColor
             locales {
               locale
               purpose
            }
          }
        }
      }
    }`
  );
  return {
    labels: parseLabels(labels.nodes, locale),
    colors: reduceLocales(colors.frontmatter.colorCodes, locale)
  };
}
