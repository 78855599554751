import React, { useEffect, useRef, useState, useContext, ReactElement } from 'react';

import { LocaleContext } from '../components/locale-context';

import getB2BSupportColors, { ColorDef } from '../queries/get-b2bsupport-colors';

type ColorProps = {
  className: string;
};

function ColorSqaure ({ className }: ColorProps): ReactElement {
  return <div className = { className } style = {{ height: '1.5em', width: '1.5em' }}></div>;
}

function ColorValue ({ className }: ColorProps): ReactElement {
  const [ colorValue, setColorValue ] = useState('');
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const colorRGB = getComputedStyle(ref.current as Element).color;
    const rgb = colorRGB.match(/\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*\)/);
    let backgroundHex = '';
    if (Array.isArray(rgb) && rgb.length === 4) {
      const RED = 1;
      const GREEN = 2;
      const BLUE = 3;
      const HEX_BASE = 16;
      const red = parseInt(rgb[RED], 10).toString(HEX_BASE).padStart(2, '0');
      const green = parseInt(rgb[GREEN], 10).toString(HEX_BASE).padStart(2, '0');
      const blue = parseInt(rgb[BLUE], 10).toString(HEX_BASE).padStart(2, '0');
      backgroundHex = `#${red}${green}${blue}`;
    }
    setColorValue(backgroundHex);
  }, []);
  return <span className = { className } style = {{ fontFamily: 'monospace' }} ref = { ref }>{ colorValue } </span>;
}

type ColorPaleteProps = {
  colors: ColorDef[];
};

function ColorPalete ({ colors }: ColorPaleteProps): ReactElement {
  return (
    <div className = "w3-section">
      <table className = "w3-table" style = {{ maxWidth: '600px' }}>
        <tbody>
          {
            colors.map((c, i) => {
              return <tr key = { i.toString() }>
                <td><ColorSqaure className = { c.backgroundColor }/></td>
                <td><ColorValue className = { c.textColor }/></td>
                <td>{ c.locales.purpose }</td>
              </tr>;
            })
          }
        </tbody>
      </table>
    </div>
  );
}

interface B2BSupportColorsProps {
  forwardRef?: React.LegacyRef<HTMLDivElement> 
}

export default function B2BSupportColors ({forwardRef}: B2BSupportColorsProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const { labels, colors } = getB2BSupportColors(locale);

  const title = labels.b2bSupportColorsTitle;
  return (
    <div className = "w3-section" title = { title } ref = {forwardRef} >
      <h2>{ title }</h2>
      <ColorPalete colors = { colors } />
    </div>
  );
}
