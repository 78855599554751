import React, { useContext, ReactElement } from "react";

import { UpperCase } from "@chweb/commonui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import { ThemeContext, ThemeFactory } from "@chweb/commonui";
import { FileDownloadOption } from "../queries/download";

interface FormatOptionsProps {
  options: FileDownloadOption[], 
}

function FormatOptions ({ options }: FormatOptionsProps): ReactElement {
  const theme = useContext(ThemeContext) || ThemeFactory.make();
  const tagStyle = `w3-tag w3-round-large w3-center ${theme.background()}`;
  return (
    <>
    {
      options.map((o, i) =>
        <p key = { i.toString() }>
          <a href = { o.file.publicURL } download = { o.file.base } style = {{ verticalAlign: 'middle' }}>
            <FontAwesomeIcon className = "w3-xlarge" icon = { faFileDownload } />
            &nbsp;
            <UpperCase
              className = { tagStyle }
              text = { o.language } />
            &nbsp;{ o.downloadDescription.downloadDescription }&nbsp;
            <UpperCase
              className = { tagStyle }
              text = { o.format } />
            &nbsp;{`(${o.file.prettySize})`}
          </a>
        </p>)
    }
    </>
  );
}

interface FileOptionsProps {
  options: FileDownloadOption[] 
}

export function FileOptions ({ options }: FileOptionsProps): ReactElement {
  const byLanguage: { [key: string]: FileDownloadOption[] } = {};
  options.reduce((acc, o) => {
    if (!acc[o.language]) {
      acc[o.language] = [];
    }
    acc[o.language].push(o);
    return acc;
  }, byLanguage);

  const langs = Object.keys(byLanguage).sort();

  return (
    <>
    {
      langs.map(l => <FormatOptions options = { byLanguage[l] } key = { l } />)
    }
    </>
  );
}
