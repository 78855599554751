import { useStaticQuery, graphql } from 'gatsby';

import { reduceToLocale } from '../libs/locale';

import { getWineLocalTitle } from '../queries/page-wine-helpers';
import { WineListData, WineListLocalized } from './fragment-wine-list';

export interface WineDescriptionData {
  id: string,
  title: string,
  html: string,
  notes: {
    vintage: number,
    note: string
  } []
}

export default function getWinesDescriptions (locale: string): WineDescriptionData[] {
  const { wines }: { wines: { frontmatter: { wines: WineListData[] }}} = useStaticQuery(
    graphql`query ($includeBottle:Boolean = false, $includeDescriptions:Boolean = true) {
      wines: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
        frontmatter {
          wines {
            ...wineList
          }
        }
      }
    }`
  );

  const winesLocalized = wines.frontmatter.wines.map(w => reduceToLocale<WineListData, WineListData, WineListLocalized & {id: string}>(w as any, locale));

  return winesLocalized.map(w => {
    return {
      id: w.id,
      title: getWineLocalTitle(w.frontmatter.pageId.pageTitle, locale),
      html: w.html,
      notes: w.frontmatter.notes
    };
  });
}
