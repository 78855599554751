import { useStaticQuery, graphql } from 'gatsby';

import {
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export default function getB2BSupportDomaine (locale: string) {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "b2bSupportDomaineTitle",
        "b2bSupportDescription",
        "b2bSupportDomaine101",
        "b2bSupportDomaineProduction",
        "b2bSupportDomaineHistory",
        "b2bSupportDomaineImages"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );
  return parseLabels(labels.nodes, locale);
}
