import { useStaticQuery, graphql } from 'gatsby';
import { ImageDownloadOption } from './download';

export interface WineImageData {
  info: ImagePurposeDescription
  downloads: ImageDownloadOption[]
}

interface ImagePurposeDescription {
  locale: string,
  title: string,
  purposeDescription: string
}

interface WineImageDataRaw {
  images: {
    frontmatter: {
      supportImages: {
        wineId: string,
        locales: ImagePurposeDescription [],
        downloadOptions: ImageDownloadOption[]
      }[],
    }
  } 
}

export default function getWineImages (wineId: string, locale: string): WineImageData[] {
  const { images }: WineImageDataRaw = useStaticQuery(
    graphql`{
        images: markdownRemark(fields: {slug: {eq: "/support-wine-images/"}}) {
          frontmatter {
            supportImages {
              wineId
              locales {
                locale
                title
                purposeDescription
              }
              downloadOptions {
                downloadOption {
                  prettySize
                  publicURL
                  base
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 800)
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }`
  );

  const flattened = images.frontmatter.supportImages.filter(i => i.wineId === wineId).map(i => {
    return {
      info: i.locales.filter(l => l.locale === locale)[0],
      downloads: i.downloadOptions
    };
  });

  return flattened;
}
