import { useStaticQuery, graphql } from 'gatsby';

import {
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export default function getB2BSupportWines (locale: string) {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "b2bSupportSocialTitle"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );
  return parseLabels(labels.nodes, locale);
}
