import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { InterractiveSection } from "@chweb/commonui";
import { CircularMenu } from '../components/circular-menu';
import InfoPanel from '../components/info-panel';
import HTMLDiv from '../components/htmldiv';
import B2BDomaine from '../components/b2bsupport-domaine';
import B2BWines from '../components/b2bsupport-wines';
import B2BSocial from '../components/b2bsupport-social';
import B2BColors from '../components/b2bsupport-colors';

import { getLocalizedValue } from '../components/locale-context';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { PageInfoData } from '../queries/fragment-page-info';

function Intro({ data }: { data: PageMarketingData }) {
  return <div className = "w3-container">
    <HTMLDiv __html = { data.md.html } />
    <InfoPanel title = { data.license.frontmatter.title }>
      <HTMLDiv __html = { data.license.html } />
    </InfoPanel>
  </div>;
}

interface MarketingPageProps {
  data: PageMarketingData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function MarketingPage ({ data, pageContext, location }: MarketingPageProps): ReactElement {
  const locale = pageContext.locale;

  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);

  const seo = PageSEO.fromLocation(location);
  updateSEO(seo, localPageInfo);

  const pageTitle = localPageInfo.title;
  return (
    <Layout locale={locale} location={location} title = { pageTitle } seo = { seo }>
      <InterractiveSection title = { pageTitle } >
        <div>
          <Intro data = { data } />
          <CircularMenu>
            <B2BDomaine />
            <B2BWines />
            <B2BSocial />
            <B2BColors />
          </CircularMenu>
        </div>
      </InterractiveSection>
    </Layout>
  );
}

interface PageMarketingData {
  md: {
    html: string
  },
  license: {
    frontmatter: {
      title: string
    },
    html: string
  },
  page: PageInfoData
}

export const query = graphql`
query($locale: String!) {
  md: markdownRemark(fields: {slug: {regex: "/support\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    html
  }
  license: markdownRemark(fields: {slug: {regex: "/support-license\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    frontmatter {
      title
    }
    html
  }
  page: pageIndexYaml(yamlId : {eq: "marketing"}) {
    ...PageInfo
  }
}
`;
