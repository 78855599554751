import React, { ReactElement, PropsWithChildren } from 'react';

type InfoPanelProps = PropsWithChildren<{ title: string }>;

export default function InfoPanel ({ title, children }: InfoPanelProps): ReactElement {
  return <div className = "w3-panel w3-leftbar cpcolor-border-brown1">
    <h4>{ title }</h4>
    { children }
  </div>;
}
