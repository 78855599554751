import { useStaticQuery, graphql } from 'gatsby';

import {
  LocalizedLabels,
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';
import { AllLocalesLabels } from './fragment-all-locales-labels';

import { HTMLNode } from './html-node';

function reduceText (nodes: HTMLNode[], locale: string): string {
  const filtered = nodes.filter(n => n.fields.locale === locale);
  return filtered.length ? filtered[0].html : nodes[0].html;
}

export type SupportWineData = {
    labels: LocalizedLabels;
    techsheetsHTML: string;
    awardsHTML: string;
}

interface B2BSupportWineData{
  labels: {
    nodes: AllLocalesLabels[]
  },
  techsheetText: {
    nodes: HTMLNode[],
  },
  awardsText: {
    nodes: HTMLNode[],
  }
}

export default function getB2BSupportWine (locale: string): SupportWineData {
  const { labels, techsheetText, awardsText }: B2BSupportWineData = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "b2bSupportWinesTitle",
        "b2bSupportWineDescription",
        "b2bSupportWineNotes",
        "b2bSupportWineTechSheets",
        "b2bSupportWineAwards",
        "b2bSupportWineImages"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
      techsheetText: allMarkdownRemark(filter: {fields: {slug: {regex: "/support-wine-techsheets\\.[a-z]{2}/"}}}) {
        nodes {
          html
          fields {
            locale
          }
        }
      }
      awardsText: allMarkdownRemark(filter: {fields: {slug: {regex: "/support-wine-awards\\.[a-z]{2}/"}}}) {
        nodes {
          html
          fields {
            locale
          }
        }
      }
    }`
  );
  return {
    labels: parseLabels(labels.nodes, locale),
    techsheetsHTML: reduceText(techsheetText.nodes, locale),
    awardsHTML: reduceText(awardsText.nodes, locale)
  };
}
