import React, { ReactElement, useContext } from 'react';

import { LocaleContext } from '../components/locale-context';

import SectionSocial from '../components/section-social';
import getB2BSupportSocial from '../queries/get-b2bsupport-social';

interface B2BSupportProps {
  forwardRef?: React.LegacyRef<HTMLDivElement> 
}

export default function B2BSupport ({ forwardRef }: B2BSupportProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getB2BSupportSocial(locale);

  const title = labels.b2bSupportSocialTitle;
  return (
    <div className = "w3-section" ref = { forwardRef } title = { title }>
      <h2>{ title }</h2>
      <SectionSocial />
    </div>
  );
}
