import { useStaticQuery, graphql } from 'gatsby';
import { HTMLValue, HTMLNode } from './html-node';


export default function getWines101 (locale: string): HTMLValue {
  const { md } = useStaticQuery(
    graphql`{
      md: allMarkdownRemark(filter: {fields: {slug: {regex: "/wines-101\\.[a-z]{2}/"}}}) {
          nodes {
            html
            fields {
              locale
            }
          }
        }
      }`
  );

  const reduced: { [key: string]: HTMLValue } = {};
  const htmlNodes: HTMLNode[] = md.nodes;
  htmlNodes.reduce((acc, n) => {
    acc[n.fields.locale] = {
      html: n.html
    };
    return acc;
  }, reduced);

  return reduced[locale];
}
