import React, { useContext, ReactElement } from 'react';

import { TabBook, Tab, TabBookOrientation } from '../components/tabbook';
import { CopyableContainer } from '../components/copyable-container';
import HTMLDiv from '../components/htmldiv';
import { Award } from '../components/award';
import { FileOptions } from '../components/tile-file';
import { SupportImages } from '../components/b2bsupport-images';
import { LocaleContext } from '../components/locale-context';
import { ThemeContext, ThemeFactory } from "@chweb/commonui";

import getWines101 from '../queries/get-wines-101';
import getB2BSupportWines from '../queries/get-b2bsupport-wines';
import getB2BSupportWine from '../queries/get-b2bsupport-wine';
import getWinesSection from '../queries/get-wines-section';

import getAwards from '../queries/get-awards';
import getWinesDescriptions, { WineDescriptionData } from '../queries/get-wines-descriptions';
import getTechnicalSheets from '../queries/get-technical';
import getWineImages from '../queries/get-wine-images';
import { HTMLValue } from '../queries/html-node';

interface WinesDescriptionProps {
  locale: string
}

function WinesDescription ({ locale }: WinesDescriptionProps): ReactElement {
  const winesData = getWinesSection();
  const localData = winesData[locale].intro;
  return <div className = "w3-container">
    <CopyableContainer>
      <HTMLDiv __html = { localData.html } />
    </CopyableContainer>
  </div>;
}

interface WineDescriptionProps {
  wine: HTMLValue 
}

function WineDescription ({ wine }: WineDescriptionProps): ReactElement {
  return <div className = "w3-container">
    <CopyableContainer>
      <HTMLDiv __html = { wine.html } />
    </CopyableContainer>
  </div>;
}

interface HTMLSectionProps {
  locale: string,
  getter: (locale: string) => HTMLValue
}

function HTMLSection ({ locale, getter }: HTMLSectionProps): ReactElement {
  const data = getter(locale);
  return <div className = "w3-container">
    <CopyableContainer>
      <HTMLDiv __html = { data.html } />
    </CopyableContainer>
  </div>;
}

interface WineRecentAwardsProps {
  locale: string,
  wine: WineDescriptionData,
}

function WineRecentAwards ({ wine, locale }: WineRecentAwardsProps): ReactElement {
  const MAX_AWARDS_PER_WINE = 5;
  const awards = getAwards(wine.id, MAX_AWARDS_PER_WINE);

  const { awardsHTML } = getB2BSupportWine(locale);

  return <div>
    <div className = "w3-container">
      { awards.map((a, i) => <Award wine = { wine } key = { i.toString() } { ...a } />)}
    </div>
    <footer className = "w3-container">
      <HTMLDiv __html = { awardsHTML } />
    </footer>
  </div>;
}

interface TastingNotesProps {
  wine: WineDescriptionData 
}

function TastingNotes ({ wine }: TastingNotesProps): ReactElement {
  let theme = useContext(ThemeContext) || ThemeFactory.make();
  theme = theme.extractSecondary();

  return <ThemeContext.Provider value = { theme }>
    <div className = 'w3-padding-16'>
      <TabBook
        orientation = { TabBookOrientation.VERTICAL }
        basicStyle = 'w3-medium'
        activeStyle = 'w3-medium'
      >
        {
          wine.notes.map((n, i) =>
            <Tab title = { `${wine.title} ${n.vintage}` } key = { i.toString() }>
              <div className = "w3-container">
                <p>{ n.note }</p>
              </div>
            </Tab>
          )
        }
      </TabBook>
    </div>
  </ThemeContext.Provider>;
}

interface TechSheetsProps {
  wine: WineDescriptionData,
  locale: string
}

function TechSheets ({ wine, locale }: TechSheetsProps): ReactElement {
  let theme = useContext(ThemeContext) || ThemeFactory.make();
  theme = theme.extractSecondary();

  const sheets = getTechnicalSheets(locale).filter(s => s.wineId === wine.id);
  const { techsheetsHTML } = getB2BSupportWine(locale);

  return <ThemeContext.Provider value = { theme }>
    <div className = 'w3-padding-16'>
      <TabBook
        orientation = { TabBookOrientation.VERTICAL }
        basicStyle = 'w3-medium'
        activeStyle = 'w3-medium'
      >
        {
          sheets.map((s, i) =>
            <Tab title = { `${s.locales.title} ${s.vintage || ''}` } titleTooltip = { s.locales.downloadDescription } key = { i.toString() }>
              <div className = "w3-container w3-padding-small" style = {{ maxWidth: '400px' }}>
                <FileOptions options = { s.options } />
              </div>
            </Tab>
          )
        }
      </TabBook>
      <footer className = "w3-container">
        <HTMLDiv __html = { techsheetsHTML } />
      </footer>
    </div>
  </ThemeContext.Provider>;
}

interface WineImagesProps {
  wine: WineDescriptionData,
  locale: string
}

function WineImages ({ wine, locale }: WineImagesProps): ReactElement {
  const data = getWineImages(wine.id, locale);
  let theme = useContext(ThemeContext) || ThemeFactory.make();
  theme = theme.extractSecondary();

  return <ThemeContext.Provider value = { theme }>
    <SupportImages options = { data } locale = { locale } />
  </ThemeContext.Provider>;
}

interface WineSectionProps {
  wine: WineDescriptionData,
  locale: string
}

function WineSection ({ wine, locale }: WineSectionProps): ReactElement {
  const theme = ThemeFactory.make(wine.id);
  const { labels } = getB2BSupportWine(locale);

  const tabList = [
    { widget: WineDescription, title: labels.b2bSupportWineDescription, enabled: true },
    { widget: TastingNotes, title: labels.b2bSupportWineNotes, enabled: Array.isArray(wine.notes) && wine.notes.length },
    { widget: TechSheets, title: labels.b2bSupportWineTechSheets, enabled: true },
    { widget: WineRecentAwards, title: labels.b2bSupportWineAwards, enabled: true },
    { widget: WineImages, title: labels.b2bSupportWineImages, enabled: true }].filter(t => t.enabled);

  return <ThemeContext.Provider value = { theme }>
    <div className = "w3-section">
      <h3 className = { theme.header() } >{ `${labels.b2bSupportWinesTitle} - ${wine.title}` }</h3>
      <TabBook enableBorder>
        {
          tabList.map(t => <Tab title = { t.title } key = { t.title }>
            { React.createElement(t.widget, { wine: wine, locale: locale }) }
          </Tab>)
        }
      </TabBook>
    </div>
  </ThemeContext.Provider>;
}

interface B2BSupportWinesProps {
  forwardRef?: React.LegacyRef<HTMLDivElement>
}

export default function B2BSupportWines ({ forwardRef }: B2BSupportWinesProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getB2BSupportWines(locale);
  const wines = getWinesDescriptions(locale);

  const title = labels.b2bSupportWinesTitle;
  return (
    <>
    <div className = "w3-section" ref = { forwardRef } title = { title }>
      <h2>{ title }</h2>
      <TabBook enableBorder>
        <Tab title = { labels.b2bSupportDescription }>
          <WinesDescription locale = { locale } />
        </Tab>
        <Tab title = { labels.b2bSupportWines101 }>
          <HTMLSection locale = { locale } getter = { getWines101 } />
        </Tab>
      </TabBook>
    </div>
    {
      wines.map((w, i) => <WineSection wine = { w } locale = { locale } key = { i.toString() } />)
    }
    </>
  );
}
