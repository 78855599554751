import React, { useState, useEffect, useRef, useContext, ReactElement } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import getCopyLabels from "../queries/get-copy";
import { LocaleContext } from "../components/locale-context";
import { ThemeContext, ThemeFactory } from "@chweb/commonui"

type TimeoutHandle = ReturnType<typeof setTimeout>;
type SetCopiedCallback = (isCopied: boolean) => void;
type SetTimerCleanupCallback = (timerHandle: TimeoutHandle| null) => void;

function copyClicked (setCopied: SetCopiedCallback, timerCleanupHandle: TimeoutHandle | null, setTimerCleanup: SetTimerCleanupCallback): void {
  setCopied(true);

  // cleaning up the time if it's running
  if (timerCleanupHandle) {
    clearTimeout(timerCleanupHandle);
    setTimerCleanup(null);
  }

  const COPIED_CLEAR_TIMEOUT = 3000;
  setTimerCleanup(setTimeout(() => {
    setCopied(false);
    setTimerCleanup(null);
  }, COPIED_CLEAR_TIMEOUT));
}

type CopyableContainerProps = {
  children: React.ReactNode
};

export function CopyableContainer ({ children }: CopyableContainerProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getCopyLabels(locale);
  const theme = useContext(ThemeContext) || ThemeFactory.make();

  const [copied, setCopied] = useState(false);
  const [timerCleanup, setTimerCleanup] = useState<TimeoutHandle|null>(null);
  const [contentText, setContentText] = useState('');

  const childrenRef = useRef<HTMLDivElement>(null);

  const getChildrenContent = (): string => {
    if (childrenRef.current) {
      return childrenRef.current.innerText || childrenRef.current.textContent || '';
    }
    return '';
  };

  useEffect(() => {
    setContentText(getChildrenContent());
    return () => {
      if (timerCleanup) {
        clearTimeout(timerCleanup);
      }
    };
  }, []);

  return <>
    <div ref = { childrenRef }>{ children }</div>
    <CopyToClipboard
      text = { contentText }
      onCopy = { () => copyClicked(setCopied, timerCleanup, setTimerCleanup) }
    >
      <div className = { `w3-container w3-padding ${theme.header()}` }
        title = { labels.copyTextAction }
        style = {{ cursor: 'pointer' }}
      >
        <span>
          <FontAwesomeIcon className = "w3-xlarge" icon = { faCopy } />
        </span> { labels.copyTextLabel }
        {
          copied ? <span className = "w3-text-green"> [ { labels.copyTextCopied }! ] </span> : null
        }
      </div>
    </CopyToClipboard>
  </>;
}
