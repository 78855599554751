import { useStaticQuery, graphql } from 'gatsby';
import { HTMLNode, HTMLValue } from '../queries/html-node';

export default function getDomaineShortHistory (locale: string): HTMLValue {
  const { md } = useStaticQuery(
    graphql`{
      md: allMarkdownRemark(filter: {fields: {slug: {regex: "/domaine-shorthistory\\.[a-z]{2}/"}}}) {
          nodes {
            html
            fields {
              locale
            }
          }
        }
      }`
  );

  const reduced: { [key: string]: HTMLValue } = {};
  const nodes: HTMLNode[] = md.nodes;
  nodes.reduce((acc, n) => {
    acc[n.fields.locale] = {
      html: n.html
    };
    return acc;
  }, reduced);

  return reduced[locale];
}
