import React, { ReactElement, useContext } from 'react';

import { TabBook, Tab } from '../components/tabbook';
import { CopyableContainer } from '../components/copyable-container';
import HTMLDiv from '../components/htmldiv';
import { LocaleContext, getLocalizedValue } from '../components/locale-context';
import { SupportImages } from '../components/b2bsupport-images';

import { getDomaineIntro } from '../queries/get-domaine-intro';
import getDomaine101 from '../queries/get-domaine-101';
import getDomaineProduction from '../queries/get-domaine-production';
import getDomaineHistory from '../queries/get-domaine-shorthistory';
import getDomaineImages from '../queries/get-domaine-images';
import getB2BSupportDomaine from '../queries/get-b2bsupport-domaine';
import { HTMLValue } from '../queries/html-node';

type DomaineDescriptionProps = {
  locale: string;
};

function DomaineDescription ({ locale }: DomaineDescriptionProps): ReactElement {
  const domaineData = getDomaineIntro();
  const localData = getLocalizedValue(domaineData, locale);
  return <div className="w3-container">
    {
      localData.intro && <CopyableContainer>
        <HTMLDiv __html={localData.intro.text} />
      </CopyableContainer>
    }
  </div>;
}

type HTMLSectionProps = {
  locale: string;
  getter: (locale: string) => HTMLValue;
};

function HTMLSection ({ locale, getter }: HTMLSectionProps): ReactElement {
  const data = getter(locale);
  return <div className = "w3-container">
    <CopyableContainer>
      <HTMLDiv __html = { data.html } />
    </CopyableContainer>
  </div>;
}

type DomaineImagesProps = {
  locale: string;
};

function DomaineImages ({ locale }: DomaineImagesProps): ReactElement {
  const options = getDomaineImages(locale);
  return <SupportImages options = { options } locale = { locale } />;
}

interface B2BSupportDomaineProps {
  forwardRef?: React.LegacyRef<HTMLDivElement> 
}

export default function B2BSupportDomaine ({forwardRef}: B2BSupportDomaineProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getB2BSupportDomaine(locale);

  const title = labels.b2bSupportDomaineTitle;
  return (
    <div className = "w3-section" title = { title } ref = {forwardRef}>
      <h2>{ title }</h2>
      <TabBook enableBorder>
        <Tab title = { labels.b2bSupportDescription }>
          <DomaineDescription locale = { locale } />
        </Tab>
        <Tab title = { labels.b2bSupportDomaine101 }>
          <HTMLSection locale = { locale } getter = { getDomaine101 } />
        </Tab>
        <Tab title = { labels.b2bSupportDomaineProduction }>
          <HTMLSection locale = { locale } getter = { getDomaineProduction } />
        </Tab>
        <Tab title = { labels.b2bSupportDomaineHistory }>
          <HTMLSection locale = { locale } getter = { getDomaineHistory } />
        </Tab>
        <Tab title = { labels.b2bSupportDomaineImages }>
          <DomaineImages locale = { locale } />
        </Tab>
      </TabBook>
    </div>
  );
}
