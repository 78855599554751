import { useStaticQuery, graphql } from 'gatsby';
import { HTMLNode, HTMLValue } from '../queries/html-node';

interface Domaine101Data {
  md: {
    nodes: {
      html: string,
      fields: {
        locale: string
      }
    }[]
  }
}

export default function getDomaine101 (locale: string): HTMLValue {
  const { md }: Domaine101Data = useStaticQuery(
    graphql`{
      md: allMarkdownRemark(filter: {fields: {slug: {regex: "/domaine-101\\.[a-z]{2}/"}}}) {
          nodes {
            html
            fields {
              locale
            }
          }
        }
      }`
  );

  const reduced: { [key: string]: HTMLValue } = {};
  const nodes: HTMLNode[] = md.nodes;
  nodes.reduce((acc, n) => {
    acc[n.fields.locale] = {
      html: n.html
    };
    return acc;
  }, reduced);

  return reduced[locale];
}
