import { useStaticQuery, graphql } from 'gatsby';

import { reduceLocales } from '../libs/locale';
import { FileDownloadOption } from './download';

export interface TechSheetDownload {
  wineId: string,
  vintage: number,
  locales: {
    locale: string,
    title: string,
    downloadDescription: string
  },
  options: FileDownloadOption[]
}

export default function getTechicalSheets (locale: string) : TechSheetDownload[] {
  const { sheets } = useStaticQuery(
    graphql`{
      sheets: markdownRemark(fields: {slug: {eq: "/support-techsheets-list/"}}) {
        frontmatter {
          pageId {
            baseUrl
          }
          downloads {
            wineId
            vintage
            locales {
              locale
              title
              downloadDescription
            }
            options {
              language
              format
              file {
                prettySize
                publicURL
              }
              downloadDescription {
                locale
                downloadDescription
              }
            }
          }
        }
      }
    }`
  );

  return reduceLocales(sheets.frontmatter.downloads, locale);
}
