import { useStaticQuery, graphql } from 'gatsby';

import {
  LocalizedLabels,
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export default function getB2BSupportImageLabels (locale: string): LocalizedLabels {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "b2bSupportImagesPurpose",
        "b2bSupportImagesOptions",
        "b2bSupportImagePreviewMessage"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );
  return parseLabels(labels.nodes, locale);
}
